<template>
    <!-- 内容管理页面 -->
    <div class="positionT0L0">
        <div v-show="$route.meta.isShow">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="left" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item ref="peopleName" label="人物姓名" prop="peopleName">
                    <a-input v-model="form.peopleName" placeholder="请输入人物姓名">
                        <div slot="addonAfter">{{ form.peopleName.length }}/45</div>
                    </a-input>
                </a-form-model-item>
                <a-form-model-item ref="identityNo" label="人物身份" prop="identityNo">
                    <a-select v-model="form.identityNo" placeholder="请选择人物身份" @change="identChange" style="width: 100%">
                        <a-select-option v-for="item in identityList" :key="item.identityNo">{{item.identityName }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item ref="familyNo" label="归属家族" prop="familyNo">
                    <a-select v-model="form.familyNo" placeholder="请选择归属家族" @change="familyChange" style="width: 100%">
                        <a-select-option v-for="item in familyList" :key="item.familyNo">{{item.familyName }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item ref="fatherNo" label="父级关系" prop="fatherNo" @change="fatherChange">
                    <a-select v-model="form.fatherNo" placeholder="请选择父级关系" style="width: 100%">
                        <a-select-option key="0">无父级</a-select-option>
                        <a-select-option v-for="item in homologyList" :key="item.peopleNo">{{item.peopleName }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item ref="yearsNo" label="生卒年份" prop="yearsNo">
                    <a-select v-model="form.yearsNo" placeholder="请选择生卒年份" style="width: 100%">
                        <a-select-option v-for="item in yearsList" :key="item.yearNo">{{item.yearName }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item ref="peopleOrder" label="排序" prop="peopleOrder">
                    <a-input-number v-model="form.peopleOrder" placeholder="请输入排序" style="width:100%"/>
                </a-form-model-item>
                <a-form-model-item ref="peoplePosition" label="坐标" prop="peoplePosition">
                    <a-input v-model="form.peoplePosition" :disabled="true" placeholder="请选择坐标" style="width:50%" class="margin_right60" />
                    <a-button type="primary" @click="peopleClick">添加坐标</a-button>
                </a-form-model-item>
                <a-form-model-item label="人物头像" prop="peopleHead">
                    <detailCropperUpload
                        :imageUrl="form.peopleHead"
                        :options="headerOptions"
                        @avatarfn="headerUrlFn"
                        avatarTextTop="建议尺寸px,JPG.PNG.JPEG格式 图片小于200K"
                        avatarTextBottom=""
                    />
                </a-form-model-item>
                <a-form-model-item label="人物半身图">
                    <detailCropperUpload
                        :imageUrl="form.peopleBust"
                        :options="bustOptions"
                        @avatarfn="bustImgUrlFn"
                        avatarTextTop="建议尺寸px JPG.PNG.JPEG格式 图片小于200k"
                        avatarTextBottom=""
                    />
                </a-form-model-item>
                <a-form-model-item ref="peopleContent" label="人物简介" prop="peopleContent">
                    <a-textarea v-model="form.peopleContent" placeholder="请输入人物简介" :rows="4" style="width:100%"/>
                </a-form-model-item>

                <a-form-model-item label="相关作品">
                    <a-select
                        v-model="form.lessonsNo"
                        mode="multiple"
                        style="width: 100%"
                        placeholder="相关作品"
                        :options = "coursesList.map(item => ({ value: item.lessonsNo,label:item.lessonsName }))"

                    />
                </a-form-model-item>

            </a-form-model>
        </div>

        <a-modal v-model="visible" title="请选择人物坐标" width="100%" wrap-class-name="full-modal">
            <div class="dialog_container">
                <div class="content">
                    <div class="column">
                        <div v-for="(i, k) in coordinateXY.familyY" :key="k">
                            <div class="col">{{i-1}}</div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="heng" v-for="(i, k) in coordinateXY.familyX" :key="k">
                            <div :class="[i-1 == 0 ? 'borColor' : 'header']">{{i-1}}</div>
                            <div :class="[((j-1)%2) == 0 ? 'zong' : 'zongColor']" v-for="(j, s) in coordinateXY.familyY" :key="s" @click="coordinate(i,j)">{{i-1}},{{j-1}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </a-modal>

        <FooterToolBar :collapsed="sideCollapsed">
            <a-button class="margin_right60" @click="() => $router.go(-1)">返回</a-button>
            <a-button type="primary" @click="keepClick" :loading="keepLoading" :disabled="keepLoading">保存</a-button>
        </FooterToolBar>
        <router-view />
    </div>
</template>

<script>
import FooterToolBar from "@/components/FooterToolbar";
import { baseMixin } from "@/store/app-mixin";
import { message, Modal } from "ant-design-vue";
import {codeFn, format} from "@/utils/tools";
import CropperUpload from "@/components/file/CropperUpload";
import MyPagination from "@/components/pagination/MyPagination";
import {
    AddOneAtlasInfo,
    GetOneAtlasInfo,
    GetYearList,
    GetFamilyList,
    GetFamilyPeople,
    GetIdentityList,
    GetAtlasLessonsList,
} from "@/request/api/coursManage";
export default {
    mixins: [baseMixin],
    components: {
        MyPagination,
        CropperUpload,
        detailCropperUpload: CropperUpload,
        FooterToolBar
    },
    created() {
        this.familyInit()
        this.identityInit()
        this.homologyInit()
        this.yearsInit()
        this.coursesInit()

        if(this.$route.params.peopleNo != 0){
            this.detailsInit()
        }

        if (codeFn("/admin/course/catalogue/getCatalogueTreeResult")) {
            this.isShowCatalogueList = true;
        }
        if (codeFn("/admin/course/lessons/getCourseLessonsResult")) {
            this.isShowCourseList = true;
        }
    },
    data() {
        return {
            visible:false,
            keepLoading:false,
            labelCol: { span: 2 },
            wrapperCol: { span: 10 },
            form: {
                peopleNo: this.$route.params.peopleNo != 0 ? this.$route.params.peopleNo : "",
                peopleName:'',
                identityNo:undefined,
                familyNo:undefined,
                fatherNo:undefined,
                yearsNo:undefined,
                peopleContent:'',
                peopleHead:'',
                peopleBust:'',
                peoplePosition:'',
                peopleOrder:'',
                lessonsNo:[],
            },
            rules: {
                peopleName: [
                    { required: true, message: "请输入人物名称", trigger: "blur" },
                    { max: 45, message: "最多输入45个字符", trigger: "change" },
                ],
                identityNo: [{ required: true, message: "请选择人物身份", trigger: "change" }],
                familyNo: [{ required: true, message: "请选择归属家族", trigger: "change" }],
                fatherNo: [{ required: true, message: "请选择父级关系", trigger: "change" }],
                yearsNo: [{ required: true, message: "请选择生卒年份", trigger: "change" }],
                peopleOrder: [{ required: true, message: "请输入排序", trigger: "blur" }],
                peoplePosition: [{ required: true, message: "请输入坐标", trigger: "blur" }],
                peopleContent: [{ required: true, message: "请输入人物简介", trigger: "blur" }],
                peopleHead: [{ required: true, message: "请上传人物头像" }],
                peopleBust: [{ required: true, message: "请上传人物半身图" }],
            },

            headerOptions: {
                maxImgSize:"20000",
                autoCrop: false, //是否默认生成截图框
                autoCropWidth: 400, //默认生成截图框宽度
                autoCropHeight: 400, //默认生成截图框高度
                fixedBox: true, //是否固定截图框大小 不允许改变
                previewsCircle: false, //预览图是否是原圆形
                title: "修改图片",
            },
            bustOptions: {
                maxImgSize:"20000",
                autoCrop: false, //是否默认生成截图框
                autoCropWidth: 400, //默认生成截图框宽度
                autoCropHeight: 400, //默认生成截图框高度
                fixedBox: true, //是否固定截图框大小 不允许改变
                previewsCircle: false, //预览图是否是原圆形
                title: "修改图片",
            },

            familyList:[],
            identityList:[],
            homologyList:[],
            yearsList:[],
            coursesList:[],
            coordinateXY:{
                familyX: 25,
                familyY: 25,
            }, // 切换家族获取XY坐标轴
            options:[],
        };
    },
    methods: {
        // 详情
        detailsInit(){
            let self = this
            GetOneAtlasInfo({
                peopleNo:self.$route.params.peopleNo
            }).then(({ code, data }) => {
                if (code == 200) {
                    self.form = data
                    self.familyList.forEach(function(i,index){
                        if(i.familyNo == data.familyNo){
                            self.coordinateXY = i
                            console.log(self.coordinateXY)
                            return
                        }
                    })
                    this.homologyInit()
                }
            });
        },

        // 获取坐标轴
        coordinate(i,j){
            let h = i-1
            let z = j-1
            let zb = `${h},${z}`
            console.log(zb)
            this.form.peoplePosition = zb
            this.visible = false
        },

        // 点击坐标输入框
        peopleClick(){
            this.visible = true
        },

        // 人物身份下拉
        identChange(){
            this.form.familyNo = ''
            this.form.fatherNo = ''
        },

        // 获取家族坐标
        familyChange(value){
            let self = this
            self.form.fatherNo = ''
            self.familyList.forEach(function(i,index){
                if(i.familyNo == value){
                    self.coordinateXY = i
                    console.log(self.coordinateXY)
                    return
                }
            })
            this.homologyInit()
        },

        // 获取父级id
        fatherChange(val){
            let self = this
            self.homologyList.forEach(function(i,index){
                if(i.fatherNo == value){
                    self.form.fatherNo = i.fatherNo
                    return
                }
            })
        },

        // 人物头像
        headerUrlFn(data) {
            this.form.peopleHead = data.url;
        },

        // 人物半身像
        bustImgUrlFn(data){
            this.form.peopleBust = data.url;
        },

        // 获取家族信息
        familyInit(){
            GetFamilyList().then(({ code, data }) => {
                if (code == 200) {
                    this.familyList = data
                }
            });
        },

        // 获取年代列表
        yearsInit(){
            GetYearList().then(({ code, data }) => {
                if (code == 200) {
                    this.yearsList = data
                }
            });
        },

        // 获取同家族人物
        homologyInit(){
            GetFamilyPeople({
                familyNo:this.form.familyNo == undefined ? '' : this.form.familyNo,
            }).then(({ code, data }) => {
                if (code == 200) {
                    this.homologyList = data
                }
            });
        },

        // 获取身份列表
        identityInit(){
            GetIdentityList().then(({ code, data }) => {
                if (code == 200) {
                    this.identityList = data
                }
            });
        },

        // 获取相关课程
        coursesInit(){
            GetAtlasLessonsList().then(({ code, data }) => {
                if (code == 200) {
                    this.coursesList = data
                }
            });
        },

        // 保存的按钮
        keepClick() {
            console.log(this.form)
            this.keepLoading = true;
            setTimeout(() => {
                this.keepLoading = false;
            }, 3000);
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    AddOneAtlasInfo(this.form).then(({ code,msg }) => {
                        if (code == 200) {
                            message.success("编辑成功");
                            location.href = `/coursManage/courseList/RelationShip`;
                        }else{
                            message.error(msg)
                        }
                    });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
/deep/.full-modal {
    .ant-modal {
        max-width: 100%;
        top: 0;
        padding-bottom: 0;
        margin: 0;
    }
    .ant-modal-content {
        display: flex;
        flex-direction: column;}
    .ant-modal-body {
        flex: 1;
    }
    .ant-modal-footer{
        display: none;
    }
}

/deep/.dialog_container{
    .content{
        width: 100%;
        display: flex;
        .box{
            width: 100%;
            display: flex;
            justify-content: center;
            .heng{
                width: 98%;
                display: flex;
                flex-direction: column;
                .header{
                    background: #e6f7ff;
                    color: #165996;
                    border: 1px solid #096dd9;
                    text-align: center;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-left: none;
                }
                .borColor{
                    background: #e6f7ff;
                    color: #165996;
                    border: 1px solid #096dd9 !important;
                    text-align: center;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-left: none;
                }
                .zong{
                    height: 40px;
                    border-right: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #000;
                }
                .zongColor{
                    background-color: #e6f7ff;
                    height: 40px;
                    border-right: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #000;
                }
            }
        }
        .column{
            width: 40px;
            display: flex;
            flex-direction: column;
            margin-top: 39px;
            border: 1px solid #096dd9;
            border-top: none;
            .col{
                background: #e6f7ff;
                border-top: 1px solid #096dd9 !important;
                color: #165996;
                width: 100%;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: none;
            }
        }
    }
}

</style>
